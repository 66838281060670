<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="RESULT"
      tooltip-title="RESULT"
      :display-breadcrumb="true"
    />
    <div v-if="isLoading">
      <Loader :content="true" />
    </div>
    <div v-else class="w-full mt-5">
      <DetailCard :details="resultDetails" custom-classes="flex-col" />
      <div>
        <Heading heading="Students" heading-size="pb-0 text-xl" />
        <div v-if="isEmpty(studentExamList)">
          <NoRecordFound class="mt-28" />
        </div>
        <TableWrapper v-else>
          <THead>
            <TRHead>
              <TH v-for="(head, index) in tableHeaders" :key="index">
                {{ head }}
              </TH>
            </TRHead>
          </THead>
          <TBody>
            <TRBody v-for="(result, index) in studentExamList" :key="index">
              <TD>
                <SingleUserDisplay :user="result.student" label="full_name" :image="true" />
              </TD>
              <TD>
                {{ result.obtained_marks.toFixed(2) }}
              </TD>
              <TD>{{ result.marks_percentage }}%</TD>
              <TD>
                <UiBadgeDisplay :options="result.weightage_wise_result" label="title" />
              </TD>
              <TD>
                {{ result.grade_title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
              <TD>
                <UiStatus :title="result.status" :success="result.status === PASS" />
              </TD>
            </TRBody>
          </TBody>
        </TableWrapper>
      </div>
    </div>
    <Pagination
      v-if="resultCounts > GENERAL_CONSTANTS.RECORD_LIMIT"
      v-show="!isLoading"
      :record-limit="GENERAL_CONSTANTS.RECORD_LIMIT"
      :max-range="paginationCounts(resultCounts, GENERAL_CONSTANTS.RECORD_LIMIT)"
      @filterRecord="filteredRecords"
    />
    <UIConfirmationModal
      v-if="isPublishedConfirmation"
      :modal="isPublishedConfirmation"
      heading="PUBLISHED_RESULT"
      message="Are you sure you want to published "
      button-text="Published"
      :name="resultDetails.title"
      remaining-message="Result ?"
      @cancel="togglePublishedResultModal"
      @confirm="publishedExamHandler"
    />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import Loader from '@components/BaseComponent/Loader.vue'
import DATE_TIME_CONSTANT from '@src/constants/date-time-constants.js'
import scrollMixin from '@src/mixins/scroll-mixin'
import Heading from '@components/Heading.vue'
import { toPascalCase } from '@utils/generalUtil'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import {
  paginationRangeHandler,
  paginationCounts,
} from '@src/components/BaseComponent/pagination.util.js'
import { fullName } from '@/src/utils/settings/tenant-user.util'
import isEmpty from 'lodash/isEmpty'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import UiBadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import UiStatus from '@src/components/UiElements/UiStatus.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
import DetailCard from '@/src/components/UiElements/DetailCard.vue'
export default {
  components: {
    TitleWrapper,
    Loader,
    Heading,
    NoRecordFound,
    SingleUserDisplay,
    UiBadgeDisplay,
    UIConfirmationModal,
    Pagination,
    UiStatus,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
    DetailCard,
  },

  mixins: [scrollMixin],
  data() {
    return {
      GENERAL_CONSTANTS,
      DATE_TIME_CONSTANT,
      PASS: 'pass',
      tableHeaders: [
        'Student',
        'Obtained Marks',
        'Marks Percentage',
        'Marks Per Exam',
        'Subject Grade',
        'Status',
      ],
      isLoading: false,
      resultDetails: {},
      currentSelectedSubject: null,
      resultCounts: 0,
      studentExamList: [],
      isPublishedConfirmation: false,
    }
  },
  page: {
    title: 'Exam Subject Result',
  },
  computed: {
    ...mapState('layout', ['currentClassScope', 'currentSectionScope']),
    currentResultId() {
      return this.$route.params.id
    },
  },
  watch: {
    currentClassScope: {
      handler() {
        this.$router.push({ name: 'subject-result' })
      },
      deep: true,
    },
    currentSectionScope: {
      handler() {
        this.$router.push({ name: 'subject-result' })
      },
      deep: true,
    },
  },
  mounted() {
    this.filteredRecords()
    this.setRightbarData()
  },
  methods: {
    isEmpty,
    paginationCounts,
    toPascalCase,
    async filteredRecords(range) {
      this.isLoading = true
      let filterQueries = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'subject_result_id', this.currentResultId),
        },
      }
      let payload = {
        ...paginationRangeHandler(range),
        ...filterQueries,
      }
      let [response, error] = await this.viewSubjectExamGeneratedResult(payload)
      this.resultDetails = this.getDetails(
        response.data.subject_result,
        response.data.subject_result_summary,
      )
      this.studentExamList = response.data.records
      this.resultCounts = response.data.meta.total_records
      this.updateExamDetails()
      this.setRightbarData(response.data.subject_result.published_at)
      this.isLoading = false
    },
    updateExamDetails() {
      this.studentExamList.forEach((result) => {
        fullName(result.student)
        result.weightage_wise_result.forEach((result) => {
          result.obtained_marks = result.obtained_marks.toFixed(2)
          result[
            'title'
          ] = `${result.exam_schedule_title} : ${result.obtained_marks} / ${result.weightage}`
        })
      })
    },
    getDetails(details, summary) {
      return {
        title: details.title,
        gradingStructure: details.non_gpa_grading.title,
        subject: details.subject.title,
        passingPercentage: `${details.passing_percentage}%`,
        totalMarks: details.total_marks,
        passingRatio: `${summary.passing_students_count} / ${summary.total_students_count}`,
        id: details.id,
      }
    },
    togglePublishedResultModal() {
      this.isPublishedConfirmation = !this.isPublishedConfirmation
    },
    setRightbarData(isPublished) {
      const rightBarContent = {
        header: {
          title: 'SUBJECT_RESULT',
          buttons: [
            {
              title: isPublished ? 'RESULT_PUBLISHED' : 'PUBLISHED_RESULT',
              classes: ['primary-button-right'],
              disable: !!isPublished,
              action: {
                func: () => {
                  this.togglePublishedResultModal()
                },
              },
            },
          ],
        },
      }
      this.setRightbarContent(rightBarContent)
    },
    async publishedExamHandler() {
      let id = this.resultDetails.id
      let [response, error] = await this.publishedSubjectResult(id)
      if (response) {
        this.filteredRecords()
        this.togglePublishedResultModal()
      }
    },
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('exams', ['viewSubjectExamGeneratedResult', 'publishedSubjectResult']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
  .timeBg {
    background-color: var(--bg-white);
  }
}
</style>
